/* Example colors */
:root {
    --type-regular: 'Roboto-Regular', -apple-system, blinkmacsystemfont, 'Segoe UI', roboto, helvetica, arial, sans-serif;
    --type-bold: 'Roboto-Medium', roboto, -apple-system, blinkmacsystemfont, 'Segoe UI', helvetica, arial, sans-serif;
    --type-mono: 'Roboto-Mono', monospace;
    --university-color: rgb(169 10 169);
    --university-color-dark: color-mix(in srgb, var(--university-color) 85%, white);
    --university-color-variant: rgb(17 19 157);
    --university-color-variant-dark: color-mix(in srgb, var(--university-color-variant) 85%, white);
}

@font-face {
    font-family: Roboto-Regular;
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/Roboto-Regular.woff2') format('woff2');
}

@font-face {
    font-family: Roboto-Medium;
    font-style: normal;
    font-weight: 500;
    src: url('/assets/fonts/Roboto-Medium.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: Roboto-Mono;
    font-weight: normal;
    src: url('/assets/fonts/roboto-mono-v12-latin-regular.woff2') format('woff2');
}

h1 {
    font-size: calc(var(--type-base-size) * 1.5);
}

h2 {
    font-size: calc(var(--type-base-size) * 1.25);
}

h3 {
    font-size: calc(var(--type-base-size) * 1.1);
}

h4 {
    font-size: calc(var(--type-base-size) * 0.85);
}

:where(h1, h2, h3, h4) {
    text-wrap: balance;
}

body {
    display: grid;
}

#app {
    background-color: var(--color-primary-dark);
    background-image: repeating-radial-gradient(circle at 0 0, transparent 0, var(--color-primary) 10px),
        repeating-linear-gradient(var(--color-primary), var(--color-primary-dark));
    min-height: 100dvh;
}

#app>.spinner-container {
    top: calc(50% - var(--spinnerCircleSize) / 2);
    left: calc(50% - var(--spinnerCircleSize) / 2);
}

@media screen and (width >=600px) {
    #app {
        display: grid;
        justify-content: center;
        place-items: center center;
    }
}

pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-x: auto;
    max-width: 90vw;
    margin-bottom: 0;
    padding: 0;
}

pre code:not(.language-javascript) {
    white-space: pre-wrap;
    overflow-x: auto;
    word-break: break-all;
    font-family: var(--type-mono);
    color: var(--color-spot);
    border: 1px solid rgb(0 0 0 / 5%);
    background-color: #f7fafc;
    padding: 0 0.25rem;
    font-size: 0.9em;
    overflow-wrap: anywhere;
    border-radius: 4px;
}

textarea {
    padding: var(--form-field-padding);
}

.select-disclosures>div {
    display: flex;
    flex-direction: column;
    gap: var(--space-1)
}

.select-disclosures .sd-property {
    display: flex;
    align-items: flex-start;
    gap: var(--space-1)
}

.select-disclosures .sd-property-value {
    display: inline-block;
    overflow-wrap: break-word;
}

.select-disclosures .sd-property-value span:first-child {
    margin-right: var(--space-1);
    font-family: var(--type-bold);
}

.select-disclosures .sd-property:has(.sd-property) {
    display: flex;
    align-items: flex-start;
}
