.sefA7W_wallet-card {
  opacity: .8;
  background-color: #0d0d36;
  background-image: repeating-radial-gradient(circle at 0 0, transparent 0, var(--university-color-dark) 10px), repeating-linear-gradient(var(--university-color-dark), var(--university-color));
  transition: transform .2s ease-in-out;
}

.sefA7W_wallet-card .sefA7W_grid-container h4 {
  font-size: 90%;
}

.sefA7W_wallet-card img {
  min-width: 48px;
}

.sefA7W_wallet-card:hover {
  transform: scale(1.5);
}

.sefA7W_wallet-card > div {
  padding: var(--space-2) var(--space-3);
}

.sefA7W_wallet-card-name-only {
  cursor: pointer;
}

.sefA7W_wallet-card-summary, .sefA7W_wallet-card-detailed {
  cursor: unset;
}

.sefA7W_wallet-card-name-only p {
  font-size: calc(var(--type-base-size) * .85);
}

.sefA7W_fade-in {
  opacity: 0;
  animation: .5s cubic-bezier(.175, .885, .32, 1.275) forwards sefA7W_fade-in-bounce;
  transform: translateY(.5rem);
}

@keyframes sefA7W_fade-in-bounce {
  0% {
    opacity: 0;
    transform: translateY(.5rem);
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

._68oDka_pwa-prompt-background {
  --overlay-background-color: #000c;
  background-color: var(--overlay-background-color);
  z-index: 999999;
  width: 100vw;
  height: 100dvh;
  min-height: 100dvh;
  transition: opacity .2s ease-in;
  position: fixed;
  top: 0;
  left: 0;
}

@media (prefers-color-scheme: dark) {
  ._68oDka_pwa-prompt-content {
    --overlay-background-color: #1d1d1d;
  }
}

._68oDka_pwa-prompt-content {
  --background-color: #f2f2f2;
  --text-color: var(--color-heading);
  --line-color: #ccc;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: var(--background-color);
  color: #000;
  filter: brightness(1.1);
  z-index: 9999999;
  border-radius: 10px;
  width: calc(100vw - 16px);
  margin: 0 8px 10px;
  transition: transform .4s cubic-bezier(.4, .24, .3, 1);
  animation: .5s forwards _68oDka_slide-up;
  position: fixed;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-color-scheme: dark) {
  ._68oDka_pwa-prompt-content {
    --text-color: #f2f2f2;
    --background-color: #1d1d1d;
    --line-color: #777;
  }
}

@keyframes _68oDka_slide-up {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

._68oDka_pwa-prompt-content-header {
  border-bottom: 1px solid var(--line-color);
  border-width: .5px;
  border-top-style: none;
  border-top-color: currentColor;
  border-left-style: none;
  border-left-color: currentColor;
  border-right-style: none;
  border-right-color: currentColor;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 13px 16px;
  display: flex;
}

._68oDka_pwa-prompt-content-header-heading {
  color: var(--text-color);
  font-size: 1rem;
}

._68oDka_pwa-prompt-content-header svg {
  stroke: var(--text-color);
}

._68oDka_lock-scroll {
  overflow: hidden;
}

._68oDka_pwa-prompt-content-copy p, ._68oDka_pwa-prompt-content-copy li {
  color: var(--text-color);
}

.HCkZgq_issuer-card {
  opacity: .8;
  background-color: #fcfcfc;
  background-image: repeating-radial-gradient(circle at 0 0, #0000 0, #fff 10px), repeating-linear-gradient(#e2e2e2, #fff);
  transition: transform .2s ease-in-out;
}

a {
  text-decoration-skip-ink: auto;
}

:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

main {
  display: block;
}

.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

textarea {
  font: inherit;
}

img {
  max-width: 100%;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  text-rendering: optimizespeed;
}

html {
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  line-height: 1.375;
}

button {
  appearance: button;
}

img {
  max-width: 100%;
  height: auto;
}

svg {
  max-height: 100%;
}

figure {
  margin: 0;
  padding: 0;
}

iframe {
  border: none;
}

article iframe {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: 100%;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: var(--type-sans);
  font-size: var(--type-base-size);
  -webkit-font-smoothing: antialiased;
  min-height: 100%;
  margin: 0;
  font-weight: 400;
  position: relative;
}

.container {
  padding-left: var(--space-1);
  padding-right: var(--space-1);
  max-width: 74em;
  margin: 0 auto;
}

@media (width >= 52em) {
  .container-wide {
    max-width: 90em;
  }
}

strong, b {
  color: currentColor;
  font-family: var(--type-sans-bold);
  font-weight: var(--type-weight-bold);
}

i, em, blockquote {
  font-style: italic;
}

html {
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background: #fff;
  overflow-y: scroll;
}

::selection {
  background: var(--color-primary-light);
  color: #fff;
  text-shadow: none;
}

img::selection {
  background: none;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--color-heading);
  font-weight: var(--type-weight);
  line-height: calc(var(--type-line-height) * .75);
  margin-bottom: var(--space-2);
  margin-top: var(--space-2);
  text-rendering: optimizelegibility;
}

.type-extra-bold {
  font-family: var(--type-sans-extra-bold);
  letter-spacing: .004em;
}

h1 {
  font-size: calc(var(--type-base-size) * 2);
}

@media (width >= 64em) {
  h1 {
    font-size: calc(var(--type-base-size) * 3);
  }
}

h2 {
  font-size: calc(var(--type-base-size) * 1.5);
}

@media (width >= 64em) {
  .h2, h2 {
    font-size: calc(var(--type-base-size) * 1.75);
  }
}

.h3, h3 {
  font-size: calc(var(--type-base-size) * 1.275);
}

@media (width >= 64em) {
  .h3, h3 {
    font-size: calc(var(--type-base-size) * 1.5);
  }
}

.h4, h4 {
  font-size: calc(var(--type-base-size) * .95);
}

@media (width >= 64em) {
  .h4, h4 {
    font-size: calc(var(--type-base-size) * 1.15);
  }
}

p {
  color: var(--color-text);
  font-weight: var(--type-weight);
  line-height: 1.5;
  line-height: calc(var(--type-line-height));
  margin-bottom: var(--space-2);
  margin-top: var(--space-2);
}

abbr {
  cursor: help;
  font-variant: small-caps;
  letter-spacing: .1em;
  text-transform: lowercase;
}

sub, sup {
  vertical-align: baseline;
  font-size: 65%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

blockquote {
  border-left: 2px solid var(--color-spot);
  color: #5a5a5a;
  font-size: calc(var(--type-base-size) * 1.25);
  margin: calc(var(--space-4)) 0 calc(var(--space-4)) calc(var(--space-2));
  padding-left: var(--space-2);
  font-style: normal;
  position: relative;
}

dl, ol, ul {
  margin-bottom: var(--space-2);
  margin-top: var(--space-2);
}

ul, ol {
  line-height: calc(var(--type-line-height));
  margin-left: 0;
}

ul {
  color: var(--color-text);
  list-style-type: disc;
}

ol {
  list-style-type: lower-roman;
}

.hidden {
  display: none;
}

code {
  background-color: var(--color-grey-subtle);
  border-radius: var(--form-field-border-radius);
  color: var(--color-spot);
  font-family: var(--type-mono);
  overflow-wrap: anywhere;
  border: 1px solid #0000000d;
  padding: 0 4px;
  font-size: 90%;
}

pre {
  font-family: var(--type-mono);
  overflow-wrap: break-word;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  border-radius: 2px;
  padding: .5rem 1rem;
  font-size: 1rem;
  overflow-x: auto;
}

pre code {
  background-color: unset;
  border-radius: unset;
  padding: unset;
  white-space: pre-wrap;
  word-break: break-all;
  overflow-x: auto;
}

.or {
  grid-gap: 20px;
  text-align: center;
  grid-template-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
  align-items: center;
  width: 100%;
  display: grid;
}

.or:before, .or:after {
  content: "";
  border-top: 1px solid #ccc;
}

a {
  color: var(--color-link);
  text-decoration: none;
  transition: color .2s;
}

a:not(.button):hover, a:not(.button):focus {
  color: var(--color-link-hover);
}

:focus-visible {
  border-color: var(--form-field-border-color-focus);
  box-shadow: var(--form-field-box-shadow-focus);
}

a:hover, a:active {
  outline: 0;
}

.field {
  background-color: var(--form-field-background-color);
  border-color: var(--form-field-border-color);
  border-radius: var(--form-field-border-radius);
  border-style: var(--form-field-border-style);
  border-width: var(--form-field-border-width);
  box-shadow: var(--form-field-box-shadow);
  caret-color: var(--form-field-caret-color);
  outline: none;
  transition: box-shadow .2s, border-color .2s, background-color .2s;
}

.field:hover {
  border-color: var(--form-field-border-color-hover);
}

.field:focus {
  border-color: var(--form-field-border-color-focus);
  box-shadow: var(--form-field-box-shadow-focus);
}

.field:disabled {
  background-color: var(--form-field-disabled-background-color);
  opacity: var(--form-field-disabled-opacity);
}

.field[readonly]:not(select), .field.is-read-only {
  background-color: var(--form-field-readonly-background-color);
}

.full-width {
  width: 100%;
}

label.is-error {
  color: var(--color-danger);
}

label {
  color: var(--form-label-color);
  font-size: var(--form-label-font-size);
  line-height: var(--form-label-line-height);
  display: inline-block;
}

input[type="text"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="week"] {
  background-color: var(--form-field-background-color);
  border-color: var(--form-field-border-color);
  border-radius: var(--form-field-border-radius);
  border-style: var(--form-field-border-style);
  border-width: var(--form-field-border-width);
  box-shadow: var(--form-field-box-shadow);
  caret-color: var(--form-field-caret-color);
  color: var(--color-primary);
  font-size: var(--form-field-font-size);
  height: var(--form-field-height);
  padding-left: var(--form-field-padding);
  padding-right: var(--form-field-padding);
  outline: none;
  transition: box-shadow .2s, border-color .2s, background-color .2s;
}

textarea {
  color: #666;
  padding: var(--form-field-padding);
}

textarea:focus {
  border-color: var(--color-primary);
  color: var(--color-primary);
  background-color: #fff;
  outline: 0;
}

button[type="submit"].button, input[type="submit"].button {
  margin-top: var(--space-1);
}

select {
  appearance: none;
  border: 1px solid var(--form-field-border-color);
  border-radius: var(--form-field-border-radius);
  box-shadow: none;
  color-adjust: exact;
  height: var(--form-field-height);
  padding-left: var(--form-field-padding);
  padding-right: calc(var(--form-field-padding) * 2);
  -webkit-print-color-adjust: exact;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3E%3Cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3E%3C/svg%3E");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  width: 100%;
  line-height: 1.2;
}

fieldset {
  border: 1px solid var(--form-field-border-color);
  border-radius: var(--form-field-border-radius);
  margin-bottom: var(--space-2);
  padding: var(--space-2);
}

::placeholder {
  color: var(--form-field-placeholder-color);
}

.form-field {
  position: relative;
}

.form-field input {
  padding-left: var(--form-field-padding-left);
}

.form-field-icon {
  color: var(--form-field-icon-color);
  display: var(--form-field-icon-display);
  font-size: var(--form-field-icon-size);
  left: 1rem;
  top: calc(var(--form-field-height) * .725);
  transition: color .3s;
  position: absolute;
}

input::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

input {
  accent-color: var(--color-spot);
}

.login-links-forgot label {
  align-items: center;
  gap: .5rem;
  display: flex;
}

input:-webkit-autofill {
  background: none;
}

input:autofill {
  background: none;
}

input:-webkit-autofill {
  transition: background-color 600000s, color 600000s;
}

input:-webkit-autofill:focus {
  transition: background-color 600000s, color 600000s;
}

input[data-autocompleted] {
  background-color: #0000 !important;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin-top: 0;
  margin-bottom: 16px;
  overflow: auto;
}

table tr {
  border-top: 1px solid #c6cbd1;
}

table th, table td {
  border: 1px solid #dfe2e5;
  padding: .5rem 1rem;
  font-size: .85rem;
}

table th {
  font-weight: 400;
}

#client-table {
  margin-top: 1rem;
}

#client-table table {
  border-collapse: collapse;
}

#client-table th, #client-table td {
  border: none;
}

#client-table td img {
  max-height: 50px;
}

#client-table tr:first-child {
  border-top: none;
}

#client-table tr:not(:last-child) {
  border-top: none;
  border-bottom: 1px solid #c6cbd1;
}

#client-table td {
  vertical-align: middle;
}

#client-table td:first-child {
  width: 30%;
}

#client-table td:nth-child(2) {
  width: 50%;
}

#client-table td:last-child {
  width: 20%;
}

.table-spinner {
  color: #ccc;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.table-spinner span {
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  transition: opacity .1s;
  display: flex;
}

.table-spinner-active span {
  opacity: 0;
}

#client-table .check_mark {
  vertical-align: middle;
  max-height: 24px;
  display: inline-block;
  position: relative;
  top: -6px;
  transform: scale(.5);
}

#client-table .check_mark .check-mark-icon {
  top: -16px;
  transform: scale(.75);
}

.table-spinner-done {
  display: none;
}

.table-spinner-done span {
  opacity: 0;
}

.table-spinner-check {
  z-index: 1;
  width: 5px;
  height: 90px;
  position: absolute;
  top: 8px;
  left: 28px;
  transform: rotate(-45deg);
}

.table-spinner-active:after {
  content: "";
  border: .15rem solid #0000;
  border-color: #0000 #0000 currentColor currentColor;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  animation: .3s linear infinite loading;
  display: block;
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
}

button, a.button, a.btn, .button, .btn {
  background-color: var(--button-color);
  border-radius: var(--button-border-radius);
  cursor: pointer;
  font-family: var(--type-sans-bold);
  font-size: var(--button-font-size);
  -webkit-font-smoothing: antialiased;
  font-weight: var(--type-weight-bold);
  align-items: center;
  gap: calc(var(--button-padding-x) * 1.25);
  padding: calc(var(--button-padding-y) * 1.25) calc(var(--button-padding-x) * 1.25);
  border: none;
  justify-content: center;
  line-height: normal;
  transition: background-color .2s, transform .2s, color .2s, box-shadow .2s, border-color .2s;
  display: inline-flex;
}

button:hover, a.button:hover, .button:hover {
  background-color: var(--button-color-hover);
  text-decoration: none;
}

button:active, a.button:active, .button:active, button:focus, a.button:focus, .button:focus {
  border-color: var(--form-field-border-color-focus);
  box-shadow: var(--form-field-box-shadow-focus);
  outline: none;
}

.login-well.form-transparent {
  --button-color: #576487;
  --button-color-hover: #4a5675;
}

a.button-primary, .button-primary {
  background-color: var(--button-color);
  border: 1px solid var(--button-color);
  color: #fff;
}

a.button-primary:hover, .button-primary:hover, a.button-primary:active, .button-primary:active {
  background-color: var(--button-color-hover);
  color: #fff;
}

a.button-primary-outline, .button-primary-outline {
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  background: none;
}

a.button-primary-outline:hover, .button-primary-outline:hover, a.button-primary-outline:active, .button-primary-outline:active {
  background: var(--color-primary);
  color: #fff;
}

a.button-white-outline, .button-white-outline {
  color: #fff;
  background: none;
  border: 1px solid #fff;
}

a.button-white-outline:hover, .button-white-outline:hover, a.button-white-outline:active, .button-white-outline:active {
  color: var(--color-primary);
  background: #fff;
}

.body-dark .form-transparent a.button-primary-outline, .body-dark .form-transparent .button-primary-outline {
  border: 1px solid var(--color-light);
  color: var(--color-light);
}

a.button-warning, .button-warning {
  background-color: var(--color-warning);
  border: 1px solid var(--color-warning);
}

a.button-warning:hover, .button-warning:hover, a.button-warning:active, .button-warning:active {
  background: var(--color-warning);
  color: #fff;
}

a.button-danger, .button-danger {
  background-color: var(--color-danger);
  border: 1px solid var(--color-danger);
  color: #fff;
}

a.button-danger:hover, .button-danger:hover, a.button-danger:active, .button-danger:active {
  background: var(--color-danger);
  color: #fff;
}

a.button-danger-outline, .button-danger-outline {
  border: 1px solid var(--color-danger);
  color: var(--color-danger);
  background-color: #0000;
}

a.button-danger-outline:hover, .button-danger-outline:hover, a.button-danger-outline:active, .button-danger-outline:active {
  background: var(--color-danger);
  color: #fff;
}

a.button-success, .button-success {
  background-color: var(--color-success);
  border: 1px solid var(--color-success);
  color: #fff;
}

a.button-success:hover, .button-success:hover, a.button-success:active, .button-success:active {
  background: var(--color-success);
  color: #fff;
}

a.button-info, .button-info {
  background-color: var(--color-info);
  border: 1px solid var(--color-info);
}

a.button-info:hover, .button-info:hover, a.button-info:active, .button-info:active {
  background: var(--color-info);
  color: #fff;
}

a.button-tiny, .button-tiny {
  font-size: .85em;
  line-height: calc(var(--button-line-height) * .75);
  padding: calc(var(--button-padding-y) * .65) calc(var(--button-padding-x) * 1);
  min-height: 24px;
}

@media (width >= 52em) {
  a.button-tiny, .button-tiny {
    min-height: 24px;
    font-size: .85em;
  }
}

a.button-small, .button-small {
  font-size: .85em;
  line-height: calc(var(--button-line-height) * 1.1);
  padding: calc(var(--button-padding-y) * .85) calc(var(--button-padding-x) * 1);
  min-height: 34px;
}

@media (width >= 52em) {
  a.button-small, .button-small {
    min-height: 34px;
    font-size: .85em;
  }
}

a.button-medium, .button-medium {
  font-size: 1.2em;
  line-height: calc(var(--button-line-height) * 1.3);
  padding: calc(var(--button-padding-y) * 1.15) calc(var(--button-padding-x) * 1.5);
  min-height: 44px;
}

@media (width >= 52em) {
  a.button-medium, .button-medium {
    font-size: 1.1em;
  }
}

a.button-large, .button-large {
  font-size: 1.25em;
  line-height: calc(var(--button-line-height) * 2);
  padding: calc(var(--button-padding-y) * 1.25) calc(var(--button-padding-x) * 1.75);
  min-height: 58px;
}

@media (width >= 52em) {
  a.button-large, .button-large {
    font-size: 1.6em;
  }
}

a.button-transparent, .button-transparent, a.button-transparent:focus, .button-transparent:focus a.button-transparent:active, .button-transparent:active {
  box-shadow: none;
  color: var(--color-primary);
  background-color: #0000;
}

a.button-transparent:hover, .button-transparent:hover {
  box-shadow: none;
  color: var(--color-primary);
  background-color: #f6f6f6;
}

.button-transparent:before {
  display: none;
}

.button-transparent:visited, a.button-transparent:visited, .button-transparent {
  background-color: #0000;
}

.button-fullwidth {
  text-align: center;
  width: 100%;
}

a.button:disabled, a.button[disabled], .button:disabled, .button[disabled], .button[disabled="disabled"], button:disabled, button[disabled="disabled"], button[disabled] {
  filter: grayscale();
  opacity: .5;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

button.button-link, .button-link, button.button-link:focus, .button-link:focus, button.button-link:active, .button-link:active {
  color: var(--color-primary);
  background-color: #0000;
}

button.button-link:hover, .button-link:hover {
  color: var(--color-primary);
  background-color: #f6f6f6;
}

body.body-dark .form-transparent button.button-link, body.body-dark .form-transparent .button-link {
  background-color: #fff;
}

a.button-loading, .button-loading {
  position: relative;
}

a.button-loading span, .button-loading span {
  justify-content: center;
  align-items: center;
  transition: opacity .1s;
  display: flex;
}

a.button-loading-active span, .button-loading-active span {
  opacity: 0;
}

a.button-loading-active:after, .button-loading-active:after {
  content: "";
  border: .15rem solid #0000;
  border-color: #0000 #0000 currentColor currentColor;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  animation: .3s linear infinite loading;
  display: block;
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.button-social .icon svg {
  fill: currentColor;
  object-fit: contain;
  width: 70%;
  height: 70%;
}

button[type="submit"].button-social, button.button-social, a.button-social, .button-social {
  border: none;
  border-color: var(--form-field-border-color);
  border-style: var(--form-field-border-style);
  border-width: var(--form-field-border-width);
  color: var(--color-heading);
  text-align: left;
  background-color: #0000;
  margin: 0;
  padding-left: 80px;
  transition: background-color .2s, transform .2s, color .2s, box-shadow .2s, border-color .2s;
}

button[type="submit"].button-social .icon, button.button-social .icon, a.button-social .icon, .button-social .icon {
  background-color: var(--color-primary);
  border-bottom-left-radius: var(--button-border-radius);
  border-top-left-radius: var(--button-border-radius);
  box-sizing: border-box;
  color: #fff;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 100%;
  font-size: 1.7rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

button[type="submit"].button-social:hover, button.button-social:hover, a.button-social:hover, .button-social:hover {
  border-color: var(--form-field-border-color-hover);
  color: var(--color-heading);
  background-color: #0000;
}

button[type="submit"].button-social-icon, button.button-social-icon, a.button-social-icon, .button-social-icon {
  border: none;
  overflow: hidden;
}

button[type="submit"].button-social-icon .icon, button.button-social-icon .icon, a.button-social-icon .icon, .button-social-icon .icon {
  border-bottom-right-radius: var(--button-border-radius);
  border-top-right-radius: var(--button-border-radius);
}

.body-dark .form-transparent button[type="submit"].button-social, .body-dark .form-transparent button.button-social, .body-dark .form-transparent a.button-social, .body-dark .form-transparent .button-social {
  background-color: var(--form-field-background-color-dark);
  color: #fff;
  border-width: 0;
}

button.button-social.button-google .icon, .button-social.button-google .icon {
  background-color: var(--authenticator-google-color);
}

button.button-social.button-pingfederate .icon, .button-social.button-pingfederate .icon {
  background-color: var(--authenticator-pingfederate-color);
}

button.button-social.button-email .icon, .button-social.button-email .icon {
  background-color: var(--authenticator-email-color);
}

button.button-social.button-oidc .icon, .button-social.button-oidc .icon {
  background-color: var(--authenticator-oidc-color);
}

button.button-social.button-group .icon, .button-social.button-group .icon {
  background-color: var(--authenticator-group-color);
}

button.button-social.button-encap .icon, .button-social.button-encap .icon {
  background-color: var(--authenticator-encap-color);
}

button.button-social.button-windows .icon, .button-social.button-windows .icon {
  background-color: var(--authenticator-windows-color);
}

button.button-social.button-siths .icon, .button-social.button-siths .icon {
  background-color: var(--authenticator-siths-color);
}

button.button-social.button-facebook .icon, .button-social.button-facebook .icon {
  background-color: var(--authenticator-facebook-color);
}

button.button-social.button-github .icon, .button-social.button-github .icon {
  background-color: var(--authenticator-github-color);
}

button.button-social.button-dropbox .icon, .button-social.button-dropbox .icon {
  background-color: var(--authenticator-dropbox-color);
}

button.button-social.button-aws .icon, .button-social.button-aws .icon {
  background-color: var(--authenticator-aws-color);
}

button.button-social.button-salesforce .icon, .button-social.button-salesforce .icon {
  background-color: var(--authenticator-salesforce-color);
}

button.button-social.button-windows-live .icon, .button-social.button-windows-live .icon {
  background-color: var(--authenticator-windows-live-color);
}

button.button-social.button-slack .icon, .button-social.button-slack .icon {
  background-color: var(--authenticator-slack-color);
}

button.button-social.button-box .icon, .button-social.button-box .icon {
  background-color: var(--authenticator-box-color);
}

button.button-social.button-bitbucket .icon, .button-social.button-bitbucket .icon {
  background-color: var(--authenticator-bitbucket-color);
}

button.button-social.button-signicat .icon, .button-social.button-signicat .icon {
  background-color: var(--authenticator-signicat-color);
}

button.button-social.button-criipto .icon, .button-social.button-criipto .icon {
  background-color: var(--authenticator-criipto-color);
}

button.button-social.button-username .icon, .button-social.button-username .icon {
  background-color: var(--authenticator-username-color);
}

button.button-social.button-stackexchange .icon, .button-social.button-stackexchange .icon {
  background-color: var(--authenticator-stackexchange-color);
}

button.button-social.button-freja-eid .icon, .button-social.button-freja-eid .icon {
  background-color: var(--authenticator-freja-eid-color);
}

button.button-social.button-cgi .icon, .button-social.button-cgi .icon {
  background-color: var(--authenticator-cgi-color);
}

button.button-social.button-instagram .icon, .button-social.button-instagram .icon {
  background-color: var(--authenticator-instagram-color);
}

button.button-social.button-twitter .icon, .button-social.button-twitter .icon {
  background-color: var(--authenticator-twitter-color);
}

button.button-social.button-linkedin .icon, .button-social.button-linkedin .icon {
  background-color: var(--authenticator-linkedin-color);
}

button.button-social.button-sms .icon, .button-social.button-sms .icon {
  background-color: var(--authenticator-sms-color);
}

button.button-social.button-html-form .icon, .button-social.button-html-form .icon {
  background-color: var(--authenticator-html-form-color);
}

button.button-social.button-totp .icon, .button-social.button-totp .icon {
  background-color: var(--authenticator-totp-color);
}

button.button-social.button-saml .icon, .button-social.button-saml .icon {
  background-color: var(--authenticator-saml-color);
}

button.button-social.button-bankid .icon, .button-social.button-bankid .icon {
  background-color: var(--authenticator-bankid-color);
}

button.button-social.button-duo .icon, .button-social.button-duo .icon {
  background-color: var(--authenticator-duo-color);
}

button.button-social.button-sign-in-with-apple .icon, .button-social.button-sign-in-with-apple .icon {
  background-color: var(--authenticator-sign-in-with-apple-color);
}

button.button-social.button-netidaccess-os .icon, .button-social.button-netidaccess-os .icon {
  background-color: var(--authenticator-netidaccess-os-color);
}

button.button-social.button-social-single-color .icon, .button-social.button-social-single-color .icon {
  background-color: var(--authenticator-social-single-color);
}

.form-field-social-icon {
  width: 50px;
  height: 50px;
  margin-right: .5rem;
  display: inline-block;
}

.form-field-social-icon a {
  height: 100%;
  position: relative;
}

.button.button-social-icon, .btn.button-social-icon {
  padding-left: inherit;
  align-items: center;
  display: block;
}

.button-social.button-social-icon .icon, .button-social.button-social-icon.button-bankid .ion-bankid {
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.95rem;
  display: inline-flex;
}

.button-social.button-social-icon.button-bankid .ion-bankid {
  background-size: 50%;
}

.button-social.button-social-icon .icon, .button-social.button-social-icon.button-google .ion-google {
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.95rem;
  display: inline-flex;
}

.button-social.button-social-icon.button-google .ion-google {
  background-size: 45%;
}

[role="progressbar"] {
  border-radius: var(--progress-bar-border-radius);
  color: #fff;
  float: left;
  font-size: 12px;
  line-height: var(--progress-bar-height);
  text-align: center;
  width: 0%;
  height: 100%;
  transition: width .6s;
}

.progress {
  height: var(--progress-bar-height);
}

.progress-success {
  background-color: var(--color-success);
}

.progress-info {
  background-color: var(--color-info);
}

.progress-warning {
  background-color: var(--color-warning);
}

div.progress .progress-danger {
  background-color: var(--color-danger);
}

.visuallyhidden {
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute !important;
}

.recipient span {
  font-family: var(--type-mono);
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: .25rem .55rem;
  font-size: 1.15rem;
  display: flex;
}

.recipient span:before {
  margin-right: 1rem;
  font-family: Ionicons;
  font-size: 1.75rem;
}

.recipient-phone span:before {
  content: "";
}

.recipient-email span:before {
  content: "";
}

.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}

.w50 {
  width: 50%;
}

.h100vh {
  height: 100vh;
}

.h-auto {
  height: auto;
}

.w-auto {
  width: auto;
}

.inlineicon {
  vertical-align: middle;
  margin-right: .5rem;
  display: inline-block;
}

.is-forbidden {
  cursor: not-allowed;
}

.is-disabled {
  pointer-events: none;
}

.circle {
  border-radius: 50%;
}

.regular {
  font-weight: 400;
}

.italic {
  font-style: italic;
}

.left-align {
  text-align: left;
}

.center {
  text-align: center;
}

.right-align {
  text-align: right;
}

.justify {
  text-align: justify;
}

.list-reset {
  padding-left: 0;
  list-style: none;
}

.uppercase {
  text-transform: uppercase;
}

.uppercasecaps {
  font-family: var(--type-sans-bold);
  -webkit-font-smoothing: antialiased;
  font-weight: var(--type-weight);
  letter-spacing: .085em;
  text-transform: uppercase;
}

.no-decoration  {
  text-decoration: none;
}

.capitalize {
  text-transform: capitalize;
}

.primary {
  color: var(--color-primary);
}

.primary-dark {
  color: var(--color-primary-dark);
}

.primary-light {
  color: var(--color-primary-light);
}

.primary-spot {
  color: var(--color-spot);
}

.tertiary {
  color: var(--color-tertiary);
}

.bg-white {
  background-color: #fff;
}

.bg-primary {
  background-color: var(--color-primary);
}

.bg-primary-dark {
  background-color: var(--color-primary-dark);
}

.bg-primary-light {
  background-color: var(--color-primary-light);
}

.bg-primary-spot {
  background-color: var(--color-spot);
}

.bg-light-grey {
  background-color: var(--color-grey-subtle);
}

.white {
  color: #fff;
}

.red {
  color: red;
}

.w-1 {
  width: 1.5rem;
}

.w-2 {
  width: 2.5rem;
}

.w-3 {
  width: 3.5rem;
}

.w-4 {
  width: 4.5rem;
}

.w-5 {
  width: 5.5rem;
}

.w-6 {
  width: 6.5rem;
}

.w-7 {
  width: 7.5rem;
}

.w-8 {
  width: 8.5rem;
}

.w-9 {
  width: 9.5rem;
}

.w-10 {
  width: 10.5rem;
}

.w-11 {
  width: 11.5rem;
}

.w-12 {
  width: 12.5rem;
}

.w-13 {
  width: 13.5rem;
}

.w-14 {
  width: 14.5rem;
}

.w-15 {
  width: 15.5rem;
}

.w-16 {
  width: 16.5rem;
}

.w-17 {
  width: 17.5rem;
}

.w-18 {
  width: 18.5rem;
}

.w-19 {
  width: 19.5rem;
}

.w-20 {
  width: 20.5rem;
}

.w-21 {
  width: 21.5rem;
}

.w-22 {
  width: 22.5rem;
}

.w-23 {
  width: 23.5rem;
}

.w-24 {
  width: 24.5rem;
}

.w-25 {
  width: 25.5rem;
}

.w-26 {
  width: 26.5rem;
}

.w-27 {
  width: 27.5rem;
}

.w-28 {
  width: 28.5rem;
}

.w-29 {
  width: 29.5rem;
}

.w-30 {
  width: 30.5rem;
}

.w-31 {
  width: 31.5rem;
}

.w-32 {
  width: 32.5rem;
}

.w-33 {
  width: 33.5rem;
}

.w-34 {
  width: 34.5rem;
}

.w-35 {
  width: 35.5rem;
}

.w-36 {
  width: 36.5rem;
}

.w-37 {
  width: 37.5rem;
}

.w-38 {
  width: 38.5rem;
}

.w-39 {
  width: 39.5rem;
}

.w-40 {
  width: 40.5rem;
}

.mw-1 {
  max-width: 1.5rem;
}

.mw-2 {
  max-width: 2.5rem;
}

.mw-3 {
  max-width: 3.5rem;
}

.mw-4 {
  max-width: 4.5rem;
}

.mw-5 {
  max-width: 5.5rem;
}

.mw-6 {
  max-width: 6.5rem;
}

.mw-7 {
  max-width: 7.5rem;
}

.mw-8 {
  max-width: 8.5rem;
}

.mw-9 {
  max-width: 9.5rem;
}

.mw-10 {
  max-width: 10.5rem;
}

.mw-11 {
  max-width: 11.5rem;
}

.mw-12 {
  max-width: 12.5rem;
}

.mw-13 {
  max-width: 13.5rem;
}

.mw-14 {
  max-width: 14.5rem;
}

.mw-15 {
  max-width: 15.5rem;
}

.mw-16 {
  max-width: 16.5rem;
}

.mw-17 {
  max-width: 17.5rem;
}

.mw-18 {
  max-width: 18.5rem;
}

.mw-19 {
  max-width: 19.5rem;
}

.mw-20 {
  max-width: 20.5rem;
}

.mw-21 {
  max-width: 21.5rem;
}

.mw-22 {
  max-width: 22.5rem;
}

.mw-23 {
  max-width: 23.5rem;
}

.mw-24 {
  max-width: 24.5rem;
}

.mw-25 {
  max-width: 25.5rem;
}

.mw-26 {
  max-width: 26.5rem;
}

.mw-27 {
  max-width: 27.5rem;
}

.mw-28 {
  max-width: 28.5rem;
}

.mw-29 {
  max-width: 29.5rem;
}

.mw-30 {
  max-width: 30.5rem;
}

.mw-31 {
  max-width: 31.5rem;
}

.mw-32 {
  max-width: 32.5rem;
}

.mw-33 {
  max-width: 33.5rem;
}

.mw-34 {
  max-width: 34.5rem;
}

.mw-35 {
  max-width: 35.5rem;
}

.mw-36 {
  max-width: 36.5rem;
}

.mw-37 {
  max-width: 37.5rem;
}

.mw-38 {
  max-width: 38.5rem;
}

.mw-39 {
  max-width: 39.5rem;
}

.mw-40 {
  max-width: 40.5rem;
}

.h-1 {
  height: 1.5rem;
}

.h-2 {
  height: 2.5rem;
}

.h-3 {
  height: 3.5rem;
}

.h-4 {
  height: 4.5rem;
}

.h-5 {
  height: 5.5rem;
}

.h-6 {
  height: 6.5rem;
}

.h-7 {
  height: 7.5rem;
}

.h-8 {
  height: 8.5rem;
}

.h-9 {
  height: 9.5rem;
}

.h-10 {
  height: 10.5rem;
}

.h-11 {
  height: 11.5rem;
}

.h-12 {
  height: 12.5rem;
}

.h-13 {
  height: 13.5rem;
}

.h-14 {
  height: 14.5rem;
}

.h-15 {
  height: 15.5rem;
}

.h-16 {
  height: 16.5rem;
}

.h-17 {
  height: 17.5rem;
}

.h-18 {
  height: 18.5rem;
}

.h-19 {
  height: 19.5rem;
}

.h-20 {
  height: 20.5rem;
}

.h-21 {
  height: 21.5rem;
}

.h-22 {
  height: 22.5rem;
}

.h-23 {
  height: 23.5rem;
}

.h-24 {
  height: 24.5rem;
}

.h-25 {
  height: 25.5rem;
}

.h-26 {
  height: 26.5rem;
}

.h-27 {
  height: 27.5rem;
}

.h-28 {
  height: 28.5rem;
}

.h-29 {
  height: 29.5rem;
}

.h-30 {
  height: 30.5rem;
}

.h-31 {
  height: 31.5rem;
}

.h-32 {
  height: 32.5rem;
}

.h-33 {
  height: 33.5rem;
}

.h-34 {
  height: 34.5rem;
}

.h-35 {
  height: 35.5rem;
}

.h-36 {
  height: 36.5rem;
}

.h-37 {
  height: 37.5rem;
}

.h-38 {
  height: 38.5rem;
}

.h-39 {
  height: 39.5rem;
}

.h-40 {
  height: 40.5rem;
}

.mh-1 {
  max-height: 1.5rem;
}

.mh-2 {
  max-height: 2.5rem;
}

.mh-3 {
  max-height: 3.5rem;
}

.mh-4 {
  max-height: 4.5rem;
}

.mh-5 {
  max-height: 5.5rem;
}

.mh-6 {
  max-height: 6.5rem;
}

.mh-7 {
  max-height: 7.5rem;
}

.mh-8 {
  max-height: 8.5rem;
}

.mh-9 {
  max-height: 9.5rem;
}

.mh-10 {
  max-height: 10.5rem;
}

.mh-11 {
  max-height: 11.5rem;
}

.mh-12 {
  max-height: 12.5rem;
}

.mh-13 {
  max-height: 13.5rem;
}

.mh-14 {
  max-height: 14.5rem;
}

.mh-15 {
  max-height: 15.5rem;
}

.mh-16 {
  max-height: 16.5rem;
}

.mh-17 {
  max-height: 17.5rem;
}

.mh-18 {
  max-height: 18.5rem;
}

.mh-19 {
  max-height: 19.5rem;
}

.mh-20 {
  max-height: 20.5rem;
}

.mh-21 {
  max-height: 21.5rem;
}

.mh-22 {
  max-height: 22.5rem;
}

.mh-23 {
  max-height: 23.5rem;
}

.mh-24 {
  max-height: 24.5rem;
}

.mh-25 {
  max-height: 25.5rem;
}

.mh-26 {
  max-height: 26.5rem;
}

.mh-27 {
  max-height: 27.5rem;
}

.mh-28 {
  max-height: 28.5rem;
}

.mh-29 {
  max-height: 29.5rem;
}

.mh-30 {
  max-height: 30.5rem;
}

.mh-31 {
  max-height: 31.5rem;
}

.mh-32 {
  max-height: 32.5rem;
}

.mh-33 {
  max-height: 33.5rem;
}

.mh-34 {
  max-height: 34.5rem;
}

.mh-35 {
  max-height: 35.5rem;
}

.mh-36 {
  max-height: 36.5rem;
}

.mh-37 {
  max-height: 37.5rem;
}

.mh-38 {
  max-height: 38.5rem;
}

.mh-39 {
  max-height: 39.5rem;
}

.mh-40 {
  max-height: 40.5rem;
}

.grid-container {
  grid-gap: 1rem;
  display: grid;
}

[grid-auto-flow="column"] {
  grid-auto-flow: column;
}

@media (width >= 52em) {
  [md-grid-auto-flow="column"] {
    grid-auto-flow: column;
  }
}

[grid-auto-columns="max-content"] {
  grid-auto-columns: max-content;
}

[grid-justify="center"] {
  justify-content: center;
}

[grid-justify="start"] {
  justify-content: start;
}

[align-center="center"] {
  align-items: center;
}

[justify-items="center"] {
  justify-items: center;
}

.span-all-columns {
  grid-column: 1 / -1;
}

[grid-auto-rows] {
  grid-auto-rows: 1fr;
}

[gap="0"] {
  grid-gap: .5rem;
}

@media (width >= 40em) {
  [gap="0"] {
    grid-gap: 0rem;
  }
}

[gap="1"] {
  grid-gap: .5rem;
}

@media (width >= 40em) {
  [gap="1"] {
    grid-gap: 1rem;
  }
}

[gap="2"] {
  grid-gap: 1rem;
}

@media (width >= 40em) {
  [gap="2"] {
    grid-gap: 2rem;
  }
}

[gap="3"] {
  grid-gap: 1rem;
}

@media (width >= 40em) {
  [gap="3"] {
    grid-gap: 3rem;
  }
}

[gap="4"] {
  grid-gap: 1rem;
}

@media (width >= 40em) {
  [gap="4"] {
    grid-gap: 4rem;
  }
}

[columns="1"] {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

[columns="2"] {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

@media (width >= 40em) {
  [sm-columns="2"] {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  [sm-columns="3"] {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (width >= 52em) {
  [md-columns="2"] {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  [md-columns="3"] {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  [md-columns="4"] {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (width >= 64em) {
  [lg-columns="2"] {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (width >= 74em) {
  [xlg-columns="2"] {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (width >= 64em) {
  [lg-columns="3"] {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  [lg-columns="4"] {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  [lg-columns="5"] {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  [lg-columns="6"] {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

.col {
  box-sizing: border-box;
  float: left;
}

.col-right {
  box-sizing: border-box;
  float: right;
}

.col-1 {
  width: 8.33333%;
}

.col-2 {
  width: 16.6667%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.3333%;
}

.col-5 {
  width: 41.6667%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.3333%;
}

.col-8 {
  width: 66.6667%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.3333%;
}

.col-11 {
  width: 91.6667%;
}

.col-12 {
  width: 100%;
}

@media (width >= 40em) {
  .sm-col {
    box-sizing: border-box;
    float: left;
  }

  .sm-col-right {
    box-sizing: border-box;
    float: right;
  }

  .sm-col-1 {
    width: 8.33333%;
  }

  .sm-col-2 {
    width: 16.6667%;
  }

  .sm-col-3 {
    width: 25%;
  }

  .sm-col-4 {
    width: 33.3333%;
  }

  .sm-col-5 {
    width: 41.6667%;
  }

  .sm-col-6 {
    width: 50%;
  }

  .sm-col-7 {
    width: 58.3333%;
  }

  .sm-col-8 {
    width: 66.6667%;
  }

  .sm-col-9 {
    width: 75%;
  }

  .sm-col-10 {
    width: 83.3333%;
  }

  .sm-col-11 {
    width: 91.6667%;
  }

  .sm-col-12 {
    width: 100%;
  }
}

@media (width >= 52em) {
  .md-col {
    box-sizing: border-box;
    float: left;
  }

  .md-col-right {
    box-sizing: border-box;
    float: right;
  }

  .md-col-1 {
    width: 8.33333%;
  }

  .md-col-2 {
    width: 16.6667%;
  }

  .md-col-3 {
    width: 25%;
  }

  .md-col-4 {
    width: 33.3333%;
  }

  .md-col-5 {
    width: 41.6667%;
  }

  .md-col-6 {
    width: 50%;
  }

  .md-col-7 {
    width: 58.3333%;
  }

  .md-col-8 {
    width: 66.6667%;
  }

  .md-col-9 {
    width: 75%;
  }

  .md-col-10 {
    width: 83.3333%;
  }

  .md-col-11 {
    width: 91.6667%;
  }

  .md-col-12 {
    width: 100%;
  }
}

@media (width >= 64em) {
  .lg-col {
    box-sizing: border-box;
    float: left;
  }

  .lg-col-right {
    box-sizing: border-box;
    float: right;
  }

  .lg-col-1 {
    width: 8.33333%;
  }

  .lg-col-2 {
    width: 16.6667%;
  }

  .lg-col-3 {
    width: 25%;
  }

  .lg-col-4 {
    width: 33.3333%;
  }

  .lg-col-5 {
    width: 41.6667%;
  }

  .lg-col-6 {
    width: 50%;
  }

  .lg-col-7 {
    width: 58.3333%;
  }

  .lg-col-8 {
    width: 66.6667%;
  }

  .lg-col-9 {
    width: 75%;
  }

  .lg-col-10 {
    width: 83.3333%;
  }

  .lg-col-11 {
    width: 91.6667%;
  }

  .lg-col-12 {
    width: 100%;
  }
}

.br-1 {
  border-radius: 1px;
}

.br-t-1 {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}

.br-t-l-1 {
  border-top-left-radius: 1px;
}

.br-t-r-1 {
  border-top-right-radius: 1px;
}

.br-b-1 {
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
}

.br-b-r-1 {
  border-bottom-right-radius: 1px;
}

.br-b-l-1 {
  border-bottom-left-radius: 1px;
}

.br-2 {
  border-radius: 2px;
}

.br-t-2 {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.br-t-l-2 {
  border-top-left-radius: 2px;
}

.br-t-r-2 {
  border-top-right-radius: 2px;
}

.br-b-2 {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.br-b-r-2 {
  border-bottom-right-radius: 2px;
}

.br-b-l-2 {
  border-bottom-left-radius: 2px;
}

.br-3 {
  border-radius: 3px;
}

.br-t-3 {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.br-t-l-3 {
  border-top-left-radius: 3px;
}

.br-t-r-3 {
  border-top-right-radius: 3px;
}

.br-b-3 {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.br-b-r-3 {
  border-bottom-right-radius: 3px;
}

.br-b-l-3 {
  border-bottom-left-radius: 3px;
}

.br-4 {
  border-radius: 4px;
}

.br-t-4 {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.br-t-l-4 {
  border-top-left-radius: 4px;
}

.br-t-r-4 {
  border-top-right-radius: 4px;
}

.br-b-4 {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.br-b-r-4 {
  border-bottom-right-radius: 4px;
}

.br-b-l-4 {
  border-bottom-left-radius: 4px;
}

.br-5 {
  border-radius: 5px;
}

.br-t-5 {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.br-t-l-5 {
  border-top-left-radius: 5px;
}

.br-t-r-5 {
  border-top-right-radius: 5px;
}

.br-b-5 {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.br-b-r-5 {
  border-bottom-right-radius: 5px;
}

.br-b-l-5 {
  border-bottom-left-radius: 5px;
}

.br-6 {
  border-radius: 6px;
}

.br-t-6 {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.br-t-l-6 {
  border-top-left-radius: 6px;
}

.br-t-r-6 {
  border-top-right-radius: 6px;
}

.br-b-6 {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.br-b-r-6 {
  border-bottom-right-radius: 6px;
}

.br-b-l-6 {
  border-bottom-left-radius: 6px;
}

.br-7 {
  border-radius: 7px;
}

.br-t-7 {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.br-t-l-7 {
  border-top-left-radius: 7px;
}

.br-t-r-7 {
  border-top-right-radius: 7px;
}

.br-b-7 {
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}

.br-b-r-7 {
  border-bottom-right-radius: 7px;
}

.br-b-l-7 {
  border-bottom-left-radius: 7px;
}

.br-8 {
  border-radius: 8px;
}

.br-t-8 {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.br-t-l-8 {
  border-top-left-radius: 8px;
}

.br-t-r-8 {
  border-top-right-radius: 8px;
}

.br-b-8 {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.br-b-r-8 {
  border-bottom-right-radius: 8px;
}

.br-b-l-8 {
  border-bottom-left-radius: 8px;
}

.z-1 {
  z-index: 1;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-center {
  align-items: center;
}

.flex-baseline {
  align-items: baseline;
}

.flex-start {
  align-items: flex-start;
}

.flex-end {
  align-items: flex-end;
}

.flex-justify {
  justify-content: space-between;
}

.flex-auto {
  flex: auto;
}

.flex-grow {
  flex: 1 0 auto;
}

.flex-none {
  flex: none;
}

@media (width >= 40em) {
  .sm-flex {
    display: flex;
  }
}

@media (width >= 52em) {
  .md-flex {
    display: flex;
  }
}

@media (width >= 64em) {
  .lg-flex {
    display: flex;
  }
}

@media (width >= 40em) {
  .flex-10 {
    flex: 0 0 10%;
  }

  .flex-20 {
    flex: 0 0 20%;
  }

  .flex-25 {
    flex: 0 0 25%;
  }

  .flex-33 {
    flex: 0 0 33.3333%;
  }

  .flex-30 {
    flex: 0 0 30%;
  }

  .flex-40 {
    flex: 0 0 40%;
  }

  .flex-50 {
    flex: 0 0 50%;
  }

  .flex-60 {
    flex: 0 0 60%;
  }

  .flex-66 {
    flex: 0 0 66.6667%;
  }

  .flex-70 {
    flex: 0 0 70%;
  }

  .flex-75 {
    flex: 0 0 75%;
  }

  .flex-80 {
    flex: 0 0 80%;
  }

  .flex-90 {
    flex: 0 0 90%;
  }

  .flex-100 {
    flex: 0 0 100%;
  }
}

.justify-start {
  justify-content: flex-start;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

@media (width >= 74em) {
  .xlg-flex {
    display: flex;
  }
}

.inline-flex {
  display: inline-flex;
}

.flex-stretch {
  align-items: stretch;
}

.flex-nostretch {
  align-self: center;
}

.flex-nostretch-start {
  align-self: flex-start;
}

.flex-nostretch-end {
  align-self: flex-end;
}

.flex-noshrink {
  flex-shrink: 0;
}

.flex-align-normal {
  align-self: normal;
}

.mt-auto {
  margin-top: auto;
}

@media (width >= 40em) {
  .sm-mb0 {
    margin-bottom: 0;
  }

  .flex-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-column-reverse {
    flex-direction: column-reverse;
  }

  .sm-justify-center {
    justify-content: center;
  }
}

@media (width >= 52em) {
  .md-justify-start {
    justify-content: flex-start;
  }
}

@media (width >= 40em) {
  .sm-flex-row {
    flex-direction: row;
  }
}

.flex-between {
  align-content: space-between;
}

.no-flex {
  flex: none;
}

.flex-gap-1 {
  gap: var(--space-1);
}

.flex-gap-2 {
  gap: var(--space-2);
}

.flex-gap-3 {
  gap: var(--space-3);
}

.flex-gap-4 {
  gap: var(--space-4);
}

.flex-row-gap-1 {
  row-gap: var(--space-1);
}

.flex-row-gap-2 {
  row-gap: var(--space-2);
}

.flex-row-gap-3 {
  row-gap: var(--space-3);
}

.flex-row-gap-4 {
  row-gap: var(--space-4);
}

:root {
  --ease-in-quad: cubic-bezier(.55, .085, .68, .53);
  --ease-in-cubic: cubic-bezier(.55, .055, .675, .19);
  --ease-in-quart: cubic-bezier(.895, .03, .685, .22);
  --ease-in-quint: cubic-bezier(.755, .05, .855, .06);
  --ease-in-expo: cubic-bezier(.95, .05, .795, .035);
  --ease-in-circ: cubic-bezier(.6, .04, .98, .335);
  --ease-out-quad: cubic-bezier(.25, .46, .45, .94);
  --ease-out-cubic: cubic-bezier(.215, .61, .355, 1);
  --ease-out-quart: cubic-bezier(.165, .84, .44, 1);
  --ease-out-quint: cubic-bezier(.23, 1, .32, 1);
  --ease-out-expo: cubic-bezier(.19, 1, .22, 1);
  --ease-out-circ: cubic-bezier(.075, .82, .165, 1);
  --ease-in-out-quad: cubic-bezier(.455, .03, .515, .955);
  --ease-in-out-cubic: cubic-bezier(.645, .045, .355, 1);
  --ease-in-out-quart: cubic-bezier(.77, 0, .175, 1);
  --ease-in-out-quint: cubic-bezier(.86, 0, .07, 1);
  --ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
  --ease-in-out-circ: cubic-bezier(.785, .135, .15, .86);
}

.ease-in-quad {
  transition-timing-function: var(--ease-in-quad);
}

.ease-in-cubic {
  transition-timing-function: var(--ease-in-cubic);
}

.ease-in-quart {
  transition-timing-function: var(--ease-in-quart);
}

.ease-in-quint {
  transition-timing-function: var(--ease-in-quint);
}

.ease-in-expo {
  transition-timing-function: var(--ease-in-expo);
}

.ease-in-circ {
  transition-timing-function: var(--ease-in-circ);
}

.ease-out-quad {
  transition-timing-function: var(--ease-out-quad);
}

.ease-out-cubic {
  transition-timing-function: var(--ease-out-cubic);
}

.ease-out-quart {
  transition-timing-function: var(--ease-out-quart);
}

.ease-out-quint {
  transition-timing-function: var(--ease-out-quint);
}

.ease-out-expo {
  transition-timing-function: var(--ease-out-expo);
}

.ease-out-circ {
  transition-timing-function: var(--ease-out-circ);
}

.ease-in-out-quad {
  transition-timing-function: var(--ease-in-out-quad);
}

.ease-in-out-cubic {
  transition-timing-function: var(--ease-in-out-cubic);
}

.ease-in-out-quart {
  transition-timing-function: var(--ease-in-out-quart);
}

.ease-in-out-quint {
  transition-timing-function: var(--ease-in-out-quint);
}

.ease-in-out-expo {
  transition-timing-function: var(--ease-in-out-expo);
}

.ease-in-out-circ {
  transition-timing-function: var(--ease-in-out-circ);
}

.m0 {
  margin: 0;
}

.mt0 {
  margin-top: 0;
}

.mr0 {
  margin-right: 0;
}

.mb0 {
  margin-bottom: 0;
}

.ml0 {
  margin-left: 0;
}

.m1 {
  margin: var(--space-1);
}

.mt1 {
  margin-top: var(--space-1);
}

.mr1 {
  margin-right: var(--space-1);
}

.mb1 {
  margin-bottom: var(--space-1);
}

.ml1 {
  margin-left: var(--space-1);
}

.m2 {
  margin: var(--space-2);
}

.mt2 {
  margin-top: var(--space-2);
}

.mr2 {
  margin-right: var(--space-2);
}

.mb2 {
  margin-bottom: var(--space-2);
}

.ml2 {
  margin-left: var(--space-2);
}

.m3 {
  margin: var(--space-3);
}

.mt3 {
  margin-top: var(--space-3);
}

.mr3 {
  margin-right: var(--space-3);
}

.mb3 {
  margin-bottom: var(--space-3);
}

.ml3 {
  margin-left: var(--space-3);
}

.m4 {
  margin: var(--space-4);
}

.mt4 {
  margin-top: var(--space-4);
}

.mr4 {
  margin-right: var(--space-4);
}

.mb4 {
  margin-bottom: var(--space-4);
}

.ml4 {
  margin-left: var(--space-4);
}

.mxn1 {
  margin-left: calc(var(--space-1) * -1);
  margin-right: calc(var(--space-1) * -1);
}

.mxn2 {
  margin-left: calc(var(--space-2) * -1);
  margin-right: calc(var(--space-2) * -1);
}

.mxn3 {
  margin-left: calc(var(--space-3) * -1);
  margin-right: calc(var(--space-3) * -1);
}

.mxn4 {
  margin-left: calc(var(--space-4) * -1);
  margin-right: calc(var(--space-4) * -1);
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.p0 {
  padding: 0;
}

.p1 {
  padding: var(--space-1);
}

.py1 {
  padding-bottom: var(--space-1);
  padding-top: var(--space-1);
}

.px1 {
  padding-left: var(--space-1);
  padding-right: var(--space-1);
}

.p2 {
  padding: var(--space-2);
}

.py2 {
  padding-bottom: var(--space-2);
  padding-top: var(--space-2);
}

.px2 {
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}

.p3 {
  padding: var(--space-3);
}

.py3 {
  padding-bottom: var(--space-3);
  padding-top: var(--space-3);
}

.px3 {
  padding-left: var(--space-3);
  padding-right: var(--space-3);
}

.p4 {
  padding: var(--space-4);
}

.py4 {
  padding-bottom: var(--space-4);
  padding-top: var(--space-4);
}

.px4 {
  padding-left: var(--space-4);
  padding-right: var(--space-4);
}

@media (width >= 64em) {
  .lg-px2 {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .lg-px3 {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .lg-px4 {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .lg-p2 {
    padding: var(--space-2);
  }

  .lg-p3 {
    padding: var(--space-3);
  }

  .lg-p4 {
    padding: var(--space-4);
  }

  .lg-mt0 {
    margin-top: 0;
  }

  .lg-mt1 {
    margin-top: var(--space-2);
  }

  .lg-mt2, .lg-mt3, .lg-mt4 {
    margin-top: var(--space-3);
  }

  .lg-mb0 {
    margin-bottom: 0;
  }

  .lg-mt1 {
    margin-bottom: var(--space-2);
  }

  .lg-mb2, .lg-mb3, .lg-mb4 {
    margin-bottom: var(--space-3);
  }
}

.line-clamp {
  display: box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
}

.inline {
  display: inline;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.table {
  display: table;
}

.table-cell {
  display: table-cell;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-auto {
  overflow: auto;
}

.overflow-x-hidden, .overflow-y-hidden {
  overflow-x: hidden;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.sm-show, .md-show, .lg-show {
  display: none !important;
}

@media (width >= 40em) {
  .sm-show {
    display: block !important;
  }
}

@media (width >= 52em) {
  .md-show {
    display: block !important;
  }
}

@media (width >= 64em) {
  .lg-show {
    display: block !important;
  }
}

@media (width >= 40em) {
  .sm-hide {
    display: none !important;
  }
}

@media (width >= 52em) {
  .md-hide {
    display: none !important;
  }
}

@media (width >= 64em) {
  .lg-hide {
    display: none !important;
  }
}

.display-none {
  display: none !important;
}

.hide {
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute !important;
}

.visuallyhidden {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.ofc {
  object-fit: cover;
}

.oft {
  object-fit: contain;
}

.off {
  object-fit: fill;
}

.border-light {
  border: 1px solid var(--color-grey-light);
}

.border-bottom-light {
  border-bottom: 1px solid var(--color-grey-light);
}

.border-top-light {
  border-top: 1px solid var(--color-grey-light);
}

.border-left-light {
  border-left: 1px solid var(--color-grey-light);
}

.border-right-light {
  border-right: 1px solid var(--color-grey-light);
}

.hover-bg-light:hover {
  background-color: var(--color-grey-subtle);
}

.hover-bg-primary-dark:hover {
  background-color: var(--color-primary-dark);
}

a.hover-color-white:hover, .hover-color-white:hover {
  color: #fff;
}

.alert-wrapper {
  padding: 1rem;
}

@media (width >= 40em) {
  .alert-wrapper {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

.alert {
  border-color: var(--alert-border-color);
  border-radius: 2px;
  border-radius: var(--alert-border-radius);
  border-style: var(--alert-border-style);
  border-width: var(--alert-border-width);
  color: var(--color-text);
  margin-bottom: 1rem;
  padding: 1rem 1rem 1rem 60px;
  font-size: .8rem;
  position: relative;
  overflow: hidden;
}

.alert:before {
  color: #0000;
  text-shadow: 0 0 #fff;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 100%;
  padding: 1.5rem;
  font-size: 1.5rem;
  line-height: 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.alert-warning {
  border-color: var(--color-warning);
  color: var(--color-warning);
}

.alert-warning:before {
  background-color: var(--color-warning);
  content: "❗";
}

.alert-danger {
  border-color: var(--color-danger);
  color: var(--color-danger);
}

.alert-danger:before {
  background-color: var(--color-danger);
  content: "❌";
}

.alert-success {
  border-color: var(--color-success);
  color: var(--color-success);
}

.alert-success:before {
  background-color: var(--color-success);
  content: "✔️";
}

.alert-info {
  border-color: var(--color-info);
  color: var(--color-info);
}

.alert-info:before {
  background-color: var(--color-info);
  content: "ℹ";
}

.body-dark .form-transparent .alert {
  color: #fff;
}

.body-dark .form-transparent .alert.alert-info {
  border: 1px solid var(--color-info);
}

.body-dark .form-transparent .alert.alert-success {
  border: 1px solid var(--color-success);
}

.body-dark .form-transparent .alert.alert-danger {
  border: 1px solid var(--color-danger);
}

.body-dark .form-transparent .alert.alert-warning {
  border: 1px solid var(--color-warning);
}

.is-error.is-error-danger {
  color: var(--color-danger);
}

.field-light.is-error.is-error-danger {
  border: 1px solid var(--color-danger);
}

.is-error.is-error-warning {
  color: var(--color-warning);
}

.field-light.is-error.is-error-warning {
  border: 1px solid var(--color-warning);
}

.is-error.is-error-success {
  color: var(--color-success);
}

.field-light.is-error.is-error-success {
  border: 1px solid var(--color-success);
}

.is-error.is-error-info {
  color: var(--color-info);
}

.field-light.is-error.is-error-info {
  border: 1px solid var(--color-info);
}

.badge {
  color: #fff;
  padding: 3px 6px;
}

.badge-warning {
  background-color: var(--color-warning);
}

.badge-danger {
  background-color: var(--color-danger);
}

.badge-success {
  background-color: var(--color-success);
}

p .badge {
  margin-left: 4px;
  margin-right: 4px;
  top: 0;
}

.well {
  padding: .75rem;
}

@media (width >= 64em) {
  .well {
    padding: 1.5rem;
  }
}

.well-grey {
  background: #00000008 padding-box padding-box;
  border-radius: 4px;
}

.well-white {
  background: #fff padding-box padding-box;
  border-radius: 4px;
}

.well-border {
  background-color: #fff;
  border: 1px solid #e6e6e6;
}

.well .qrcode {
  max-width: 200px;
}

.well p {
  margin-bottom: 0;
}

.spinner-container {
  --spinnerSize: 80px;
  --spinnerCircleSize: 80px;
  --spinnerLogoSize: 40px;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.spinner {
  width: var(--spinnerSize);
  height: var(--spinnerSize);
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.spinner-circle {
  border-color: color-mix(in srgb, var(--color-primary) 30%, white) transparent transparent;
  transform-origin: 50%;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  margin: auto;
  animation: .5s linear infinite spin;
  position: absolute;
  inset: 0;
}

.spinner-medium .spinner-circle {
  border-width: 2px;
  width: 72px;
  height: 72px;
}

.spinner-logo {
  width: var(--spinnerLogoSize);
  height: var(--spinnerLogoSize);
  fill: var(--color-primary);
  position: relative;
  left: -5%;
}

.spinner-top-line {
  opacity: 0;
  transition: transform .5s, opacity .5s .5s;
  animation: 1s cubic-bezier(.64, .57, .67, 1.53) infinite top-animation;
}

.spinner-middle-line {
  opacity: 0;
  transition: transform .5s, opacity .5s .5s;
  animation: 1s cubic-bezier(.64, .57, .67, 1.53) .45s infinite middle-animation;
}

.spinner-tiny-line {
  opacity: 0;
  transition: transform .5s, opacity .5s .5s;
  animation: .5s cubic-bezier(.64, .57, .67, 1.53) .5s infinite tiny-animation;
}

.spinner-bottom-line {
  opacity: 0;
  transform-origin: 0%;
  transition: transform .5s, opacity .5s .5s;
  animation: 1s cubic-bezier(.64, .57, .67, 1.53) .6s infinite bottom-animation;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes bottom-animation {
  0% {
    transform: translate(9px);
  }

  50% {
    opacity: 1;
    transform: translate(4px);
  }

  to {
    opacity: 0;
    transform: translate(2px);
  }
}

@keyframes tiny-animation {
  0% {
    transform: translate(2px);
  }

  50% {
    opacity: 1;
    transform: translate(2px);
  }

  70% {
    opacity: 0;
    transform: translate(2px);
  }
}

@keyframes top-animation {
  0% {
    transform: translate(16px);
  }

  50% {
    opacity: 1;
    transform: translate(8px);
  }

  to {
    opacity: 0;
    transform: translate(2px);
  }
}

@keyframes middle-animation {
  0% {
    transform: translate(16px);
  }

  50% {
    opacity: 1;
    transform: translate(8px);
  }

  to {
    opacity: 0;
    transform: translate(2px);
  }
}

.spinner-container-transparent {
  background-color: #0000;
}

.spinner-container-transparent .spinner-circle {
  border-color: var(--color-primary-light) transparent transparent;
}

.spinner-container-transparent .spinner-logo {
  fill: var(--color-primary-light);
}

.pill {
  color: #fff;
  font-family: var(--font-bold);
  -webkit-font-smoothing: antialiased;
  letter-spacing: .025em;
  text-rendering: optimizelegibility;
  text-transform: uppercase;
  border: none;
  border-radius: 1rem;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  padding: 0 .5rem;
  font-size: .75rem;
  display: inline-flex;
}

.pill:hover {
  text-decoration: none;
}

.pill-grey {
  color: #666;
  background-color: #eee;
  border-color: #ddd;
}

.pill-success {
  border: 1px solid var(--color-success);
  color: var(--color-success);
}

.pill-warning {
  border: 1px solid var(--color-warning);
  color: var(--color-warning);
}

.pill-danger {
  border: 1px solid var(--color-danger);
  color: var(--color-danger);
}

.pill-bright {
  background-color: var(--color-spot);
  color: #fff;
  border: none;
}

.pill-primary {
  background-color: var(--color-primary);
  color: #fff;
  border: none;
}

.pill-danger {
  background-color: var(--color-danger);
  color: #fff;
  border: none;
}

.success-checkmark-circle {
  fill: none;
  stroke: var(--color-success);
  stroke-dasharray: 166;
  stroke-dashoffset: 166px;
  stroke-miterlimit: 10;
  stroke-width: 3px;
  animation: .6s cubic-bezier(.65, 0, .45, 1) forwards success-stroke;
}

.success-checkmark {
  box-shadow: inset 0 0 0 var(--color-success);
  stroke: var(--color-success);
  stroke-miterlimit: 10;
  stroke-width: 3px;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  margin: 2rem auto;
  animation: .4s ease-in-out .4s forwards success-fill, .3s ease-in-out .9s both scale;
  display: block;
}

.success-checkmark-check {
  stroke-dasharray: 48;
  stroke-dashoffset: 48px;
  transform-origin: 50%;
  animation: .3s cubic-bezier(.65, 0, .45, 1) .8s forwards success-stroke;
}

.modal .success-checkmark-circle {
  animation: none;
}

.modal.active .success-checkmark-circle {
  animation: .6s cubic-bezier(.65, 0, .45, 1) forwards success-stroke;
}

.modal .success-checkmark {
  animation: none;
}

.modal.active .success-checkmark {
  animation: .4s ease-in-out .4s forwards success-fill, .3s ease-in-out .9s both scale;
}

.modal .success-checkmark-check {
  animation: none;
}

.modal.active .success-checkmark-check {
  animation: .3s cubic-bezier(.65, 0, .45, 1) .8s forwards success-stroke;
}

@keyframes success-stroke {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes success-scale {
  0%, to {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes success-fill {
  to {
    box-shadow: inset 0 0 0 30px #fff;
  }
}

:root {
  --color-text: #737373;
  --color-heading: #262c3d;
  --color-light: white;
  --color-primary: #323c53;
  --color-primary-dark: #262c3d;
  --color-primary-medium: #3c4764;
  --color-primary-light: #7e89a8;
  --color-tertiary: #f2eb80;
  --color-success: #57c75c;
  --color-info: #62818f;
  --color-danger: #ca2e2b;
  --color-warning: #e0c01c;
  --color-add: #0092ff;
  --color-spot: #d859a1;
  --color-link: #d859a1;
  --color-link-hover: #87148b;
  --color-grey-light: #ccc;
  --color-grey-subtle: #f6f6f6;
  --form-label-color: #666;
  --form-label-line-height: 2;
  --form-label-font-size: calc(var(--type-base-size) * .85);
  --form-field-disabled-background-color: #00000020;
  --form-field-disabled-opacity: .5;
  --form-field-readonly-background-color: #00000020;
  --form-field-background-color: white;
  --form-field-background-color-dark: var(--color-primary-dark);
  --form-field-placeholder-color: #8d8d8d;
  --form-field-border-color: #cfd9e0;
  --form-field-box-shadow: none;
  --form-field-box-shadow-focus: #98cbff 0 0 0 3px;
  --form-field-border-color-focus: #0059c8;
  --form-field-height: 2.55rem;
  --form-field-padding: .75rem;
  --form-field-padding-left: .75rem;
  --form-field-icon-display: none;
  --form-field-icon-color: #ccc;
  --form-field-icon-size: 1.5rem;
  --form-field-border-radius: 8px;
  --form-field-border-style: solid;
  --form-field-border-width: 1px;
  --form-field-border-color: #cfd9e0;
  --form-field-border-color-hover: #989899;
  --form-field-border-color-dark: var(--color-primary-dark);
  --form-field-caret-color: var(--color-primary);
  --type-sans: "Roboto-Regular", system, -apple-system, "Roboto", "Segoe UI", "Lucida Grande", sans-serif;
  --type-weight: normal;
  --type-sans-bold: "Roboto-Medium", system, -apple-system, "Roboto", "Segoe UI", "Lucida Grande", sans-serif;
  --type-sans-extra-bold: "Roboto-Bold", Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
  --type-weight-bold: normal;
  --type-mono: "SF Mono", consolas, monaco, "Courier New", courier, monospace;
  --type-line-height: 1.5;
  --type-base-size: 1rem;
  --space-1: .5rem;
  --space-2: calc(var(--space-1) * 2);
  --space-3: calc(var(--space-1) * 4);
  --space-4: calc(var(--space-1) * 8);
  --progress-bar-height: 6px;
  --progress-bar-border-radius: 6px;
  --button-padding-y: .6rem;
  --button-padding-x: 1rem;
  --button-border-radius: 8px;
  --button-font-size: calc(var(--type-base-size));
  --button-color: var(--color-primary);
  --button-color-hover: var(--color-primary-dark);
  --authenticator-google-color: #4285f4;
  --authenticator-pingfederate-color: #bc1f27;
  --authenticator-email-color: grey;
  --authenticator-oidc-color: #f78c40;
  --authenticator-group-color: green;
  --authenticator-encap-color: #fbb034;
  --authenticator-windows-color: #0078d7;
  --authenticator-siths-color: #008272;
  --authenticator-facebook-color: #3b5998;
  --authenticator-linkedin-color: #0077b5;
  --authenticator-sms-color: #70b29c;
  --authenticator-html-form-color: #626c87;
  --authenticator-saml-color: #666;
  --authenticator-totp-color: #4682b4;
  --authenticator-github-color: #333;
  --authenticator-twitter-color: #1da1f2;
  --authenticator-bankid-color: #235971;
  --authenticator-dropbox-color: #007ee5;
  --authenticator-aws-color: #f90;
  --authenticator-salesforce-color: #1798c1;
  --authenticator-windows-live-color: #d83b01;
  --authenticator-slack-color: #6ecadc;
  --authenticator-box-color: #0061d5;
  --authenticator-bitbucket-color: #205081;
  --authenticator-instagram-color: #c13584;
  --authenticator-signicat-color: #212831;
  --authenticator-stackexchange-color: #1e5397;
  --authenticator-criipto-color: #edb0ab;
  --authenticator-freja-eid-color: #48429e;
  --authenticator-cgi-color: #c03;
  --authenticator-duo-color: #6dc04f;
  --authenticator-sign-in-with-apple-color: #000;
  --authenticator-social-single-color: var(--color-primary);
  --authenticator-username-color: var(--color-primary);
  --authenticator-netidaccess-os-color: #000;
  --alert-border-radius: 8px;
  --alert-border-style: solid;
  --alert-border-width: 1px;
  --alert-border-color: #cfd9e0;
  --done-checkmark-container-size: 100px;
  --done-checkmark-size: 80px;
  --done-checkmark-line-color: var(--color-success);
  --done-checkmark-circle-color: var(--color-success);
}

:root {
  --type-regular: "Roboto-Regular", -apple-system, blinkmacsystemfont, "Segoe UI", roboto, helvetica, arial, sans-serif;
  --type-bold: "Roboto-Medium", roboto, -apple-system, blinkmacsystemfont, "Segoe UI", helvetica, arial, sans-serif;
  --type-mono: "Roboto-Mono", monospace;
  --university-color: #a90aa9;
  --university-color-dark: color-mix(in srgb, var(--university-color) 85%, white);
  --university-color-variant: #11139d;
  --university-color-variant-dark: color-mix(in srgb, var(--university-color-variant) 85%, white);
}

@font-face {
  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: 400;
  src: url("Roboto-Regular.31550cf1.woff2") format("woff2");
}

@font-face {
  font-family: Roboto-Medium;
  font-style: normal;
  font-weight: 500;
  src: url("Roboto-Medium.8036abf1.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Roboto-Mono;
  font-weight: normal;
  src: url("roboto-mono-v12-latin-regular.cf1eee5f.woff2") format("woff2");
}

h1 {
  font-size: calc(var(--type-base-size) * 1.5);
}

h2 {
  font-size: calc(var(--type-base-size) * 1.25);
}

h3 {
  font-size: calc(var(--type-base-size) * 1.1);
}

h4 {
  font-size: calc(var(--type-base-size) * .85);
}

:where(h1, h2, h3, h4) {
  text-wrap: balance;
}

body {
  display: grid;
}

#app {
  background-color: var(--color-primary-dark);
  background-image: repeating-radial-gradient(circle at 0 0, transparent 0, var(--color-primary) 10px), repeating-linear-gradient(var(--color-primary), var(--color-primary-dark));
  min-height: 100dvh;
}

#app > .spinner-container {
  top: calc(50% - var(--spinnerCircleSize) / 2);
  left: calc(50% - var(--spinnerCircleSize) / 2);
}

@media screen and (width >= 600px) {
  #app {
    justify-content: center;
    place-items: center;
    display: grid;
  }
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 90vw;
  margin-bottom: 0;
  padding: 0;
  overflow-x: auto;
}

pre code:not(.language-javascript) {
  white-space: pre-wrap;
  word-break: break-all;
  font-family: var(--type-mono);
  color: var(--color-spot);
  overflow-wrap: anywhere;
  background-color: #f7fafc;
  border: 1px solid #0000000d;
  border-radius: 4px;
  padding: 0 .25rem;
  font-size: .9em;
  overflow-x: auto;
}

textarea {
  padding: var(--form-field-padding);
}

.select-disclosures > div {
  gap: var(--space-1);
  flex-direction: column;
  display: flex;
}

.select-disclosures .sd-property {
  align-items: flex-start;
  gap: var(--space-1);
  display: flex;
}

.select-disclosures .sd-property-value {
  overflow-wrap: break-word;
  display: inline-block;
}

.select-disclosures .sd-property-value span:first-child {
  margin-right: var(--space-1);
  font-family: var(--type-bold);
}

.select-disclosures .sd-property:has(.sd-property) {
  align-items: flex-start;
  display: flex;
}

.TcDlAG_footer {
  --height: 68px;
  height: var(--height);
  border-top: 1px solid var(--color-grey-light);
  background-color: #fff;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  padding-bottom: 1rem;
  bottom: 0;
  left: 0;
}

@media screen and (width >= 600px) {
  .TcDlAG_footer {
    --height: 48px;
    padding-bottom: 0;
  }
}

a.TcDlAG_footer-button-qrcode {
  background-color: var(--color-spot);
  border-radius: 50%;
  padding: 0;
}

.A2BXWG_layout {
  background-color: #fff;
  width: 100vw;
  height: 100dvh;
  position: relative;
}

@media screen and (width >= 600px) {
  .A2BXWG_layout {
    border-radius: 16px;
    max-width: 375px;
    height: 836px;
    min-height: 400px;
    max-height: 694px;
  }
}

.o781xq_header {
  border-bottom: 1px solid var(--color-grey-light);
  height: 48px;
  top: 0;
  left: 0;
}

.o781xq_header-button {
  position: absolute;
  left: 0;
}

.HoVzNa_inner {
  inset: 48px 0;
  overflow: hidden auto;
}

.HoVzNa_video {
  object-fit: cover;
  object-position: center;
  aspect-ratio: 4 / 3;
  max-width: 100%;
}

.xzc0LG_debug-pane {
  --height: 100dvh;
  --header-height: 48px;
  --width: 100%;
  --visibility: none;
  z-index: 1;
  display: var(--visibility);
  height: var(--height);
  width: var(--width);
  color: #fff;
  border-top-left-radius: var(--form-field-border-radius);
  background-color: #111;
  position: fixed;
  top: 0;
  right: 0;
}

@media screen and (width >= 600px) {
  .xzc0LG_debug-pane {
    --visibility: block;
    --width: 460px;
  }
}

.xzc0LG_debug-pane-minimized {
  height: var(--header-height);
  top: auto;
  bottom: 0;
  overflow: clip;
}

.xzc0LG_debug-pane-header {
  height: var(--header-height);
  padding-left: var(--space-2);
  padding-right: var(--space-2);
  cursor: pointer;
  border-bottom: 1px solid #333;
}

.xzc0LG_debug-pane pre {
  tab-size: 4;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  text-wrap: nowrap;
  white-space-collapse: preserve;
  hyphens: auto;
  text-indent: 5em hanging each-line;
  font-feature-settings: normal;
  overflow: auto;
}

.xzc0LG_debug-pane-close:hover {
  color: #fff;
  background-color: #0000;
}

.r1vTpa_picker-button {
  align-items: unset;
}

.r1vTpa_picker-button:hover {
  color: initial;
  background-color: #0000;
}

.r1vTpa_picker-button-list {
  z-index: 1;
  border: 1px solid var(--color-grey-light);
  background-color: #fff;
  top: calc(100% + 8px);
  left: 0;
  box-shadow: 0 50px 100px -20px #32325d40, 0 30px 60px -30px #0000004d;
}

.r1vTpa_picker-button-list li:not(:last-child) {
  border-bottom: 1px solid var(--color-grey-light);
}

.r1vTpa_picker-button-item {
  text-align: left;
}

.r1vTpa_picker-button-item-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 22ch;
  overflow: hidden;
}

.CookieConsent {
  color: initial;
  z-index: 99999;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  max-width: 500px;
  margin: 1rem;
  padding: 1rem;
  font-size: 14px;
  position: fixed;
  bottom: 1rem;
  right: 0;
}

.CookieConsent a {
  color: var(--color-spot);
  text-decoration: underline;
}

#rcc-confirm-button, #rcc-decline-button {
  color: #fff;
  background-color: #000;
  border: 1px solid #000;
  border-radius: 8px;
  margin: .5rem;
  padding: .5rem 1.2rem;
  font-size: 14px;
}

#rcc-decline-button {
  color: #000;
  background-color: #fff;
}

#rcc-decline-button:hover {
  color: #fff;
}

#rcc-confirm-button:hover, #rcc-decline-button:hover {
  background-color: #666;
}

/*# sourceMappingURL=index.f3e8dafc.css.map */
